import {
  Grid,
  GridColumn,
  Text,
  ThemeProvider,
  UnbrandedEducationTheme
} from "@modernatx/ui-kit-react";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import React from "react";
import { Global } from "theme-ui";

import { RenderMetadata } from "@/components/RenderMetadata";
import { RsvContent, RsvContentProvider } from "@/components/rsv/hooks/useRsvContent";
import { RenderContent } from "@/components/rsv/RenderContent";
import { ExitModalProvider } from "@/hooks/useExitModal";
import { NavPanelProvider } from "@/hooks/useNavPanel";
import { BlockPageProps } from "@/types/Block";
import { Experience, getExperience } from "@/utils/experience";
import { getIsProduction } from "@/utils/production";

export const getServerSideProps: GetServerSideProps<{
  content: (BlockPageProps & RsvContent) | null;
  experience: Experience | null;
  isProduction: boolean;
}> = async ({ req }) => {
  const experience = getExperience(req);
  const isProduction = getIsProduction();
  let content: { default: BlockPageProps & RsvContent } | undefined;

  if (experience === null) {
    return {
      props: {
        content: null,
        experience,
        isProduction
      }
    };
  }

  try {
    content = await import(`../profiles${experience.profile}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Invalid profile path:", e);
    return {
      notFound: true
    };
  }

  if (!content || (isProduction && !experience.isProduction)) {
    return {
      props: {
        content: null,
        experience,
        isProduction
      }
    };
  }

  return {
    props: {
      content: content.default,
      experience,
      isProduction
    }
  };
};

const Rsv = ({ content, experience }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { country } = experience || {};
  const background = (
    <Global
      styles={{
        html: {
          backgroundColor: "white"
        }
      }}
    />
  );

  if (!country || !content) {
    return (
      <ThemeProvider>
        {background}
        <Grid>
          <GridColumn
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <Text size="lg" as="p">
              The site is not available in your country.
            </Text>
          </GridColumn>
        </Grid>
      </ThemeProvider>
    );
  }
  return (
    <RsvContentProvider content={content}>
      <ExitModalProvider exitModal={content.exitModal}>
        <NavPanelProvider>
          <RenderMetadata {...content.metadata} />
          <ThemeProvider theme={UnbrandedEducationTheme} usingStyleInheritance={true}>
            <Global
              styles={{
                html: {
                  backgroundColor: "background01"
                }
              }}
            />
            <RenderContent content={content} />
          </ThemeProvider>
        </NavPanelProvider>
      </ExitModalProvider>
    </RsvContentProvider>
  );
};

export default Rsv;
